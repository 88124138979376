import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import {COURSES, GAMES, PREMIUM, SCENARIOS} from "./constants";
import {Item} from "./Item";
import {useTranslation} from "react-i18next";
import "./styles.scss"
import {useNavigate} from "react-router";
import {COURSES as CourseRoute, GAMES_ROUTE, PREMIUM_ROUTE, SCENARIOS_ROUTE} from '../../../constants/routes.constants'
import {getAuth} from "firebase/auth";
import {
    calculateCourseProgress,
    calculateUnitProgress, getAllUnits, getListOfFavorites,
    getUnitUserDataToDatabase,
    ProgressCalcResponse
} from "../../../utils/firebase/userData.utils";
import {useCallback, useEffect, useState} from "react";
import {TFavoritesList} from "../FavoritesPage/FavoritesPage";
import {auth} from "../../../firebase/firebase.config";

type TAllResults = {
    courses: boolean[][],
    games: boolean[][],
    premium: boolean[][],
    scenarios: boolean[][]
}

const initialAllResults = {
    courses: [],
    games: [],
    premium: [],
    scenarios: []
}

export const Main = () => {
    const navigate=useNavigate();
    const { t } = useTranslation('app');
    const coursesInfo = t('course_info', { returnObjects: true })
    const courseTitle = t('course')
    const gamesInfo = t('games_info', { returnObjects: true })
    const gamesTitle = t('games')
    const scenariosInfo = t('scenarios_info', { returnObjects: true })
    const premiumInfo = t('premium_info', { returnObjects: true })
    const scenariosTitle = t('scenarios')
    const premiumTitle = t('premium')

    const [coursesViewed, setCoursesViewed] = useState<ProgressCalcResponse>({ sumOfViewed: 0, sumOfItems: 0 })
    const [allResults, setAllResults] = useState<TAllResults>(initialAllResults)
    const [gamesViewed, setGamesViewed] = useState<ProgressCalcResponse>({ sumOfViewed: 0, sumOfItems: 0 })
    const [scenariosViewed, setScenariosViewed] = useState<ProgressCalcResponse>({ sumOfViewed: 0, sumOfItems: 0 })
    const [premiumViewed, setPremiumViewed] = useState<ProgressCalcResponse>({ sumOfViewed: 0, sumOfItems: 0 })

    const user= getAuth()

    const getCoursesViewed = useCallback(async() => {
        if(!user?.currentUser?.uid) return
        const res = await getUnitUserDataToDatabase(user?.currentUser?.accessToken, "courses")
         setCoursesViewed(calculateUnitProgress(res))
    }, [])
    const getAll = useCallback(async() => {
        if(!user?.currentUser?.uid) return
        const res = await getAllUnits(user?.currentUser?.accessToken)
         setAllResults(res)
    }, [])
    const getGamesViewed = useCallback(async() => {
        if(!user?.currentUser?.uid) return
        const res = await getUnitUserDataToDatabase(user?.currentUser?.accessToken, "games")
         setGamesViewed(calculateUnitProgress(res))
    }, [])
    const getScenariosViewed = useCallback(async() => {
        if(!user?.currentUser?.uid) return
        const res = await getUnitUserDataToDatabase(user?.currentUser?.accessToken, "scenarios")
        setScenariosViewed(calculateUnitProgress(res))
    }, [])
    const getPremiumViewed = useCallback(async() => {
        if(!user?.currentUser?.uid) return
        const res = await getUnitUserDataToDatabase(user?.currentUser?.accessToken, "premium")
        setPremiumViewed(calculateUnitProgress(res))
    }, [])

    useEffect(() => {
        if(!user?.currentUser?.uid) return
        getCoursesViewed()
        getGamesViewed()
        getScenariosViewed()
        getPremiumViewed()
        getAll()
        localStorage.removeItem('price_plan')
        localStorage.removeItem('isTimerOver')
    }, [user])

    const handleRedirect = (route: string) => {
        navigate(route)
    }
    const [list, setList] = useState<TFavoritesList>({})
    const getFavorites = async () => {
        const res =  await getListOfFavorites(user?.currentUser?.accessToken)
        setList(res)
    }

    useEffect(() =>{
        getFavorites()
    }, [user])

    return(
        <div className="main-content app-wrapper mt-32">
            <div className="main-content__header-wrapper row-container space-between">
                <div className="main-content__title typography-24">{courseTitle}</div>
                <div className="main-content__title-count typography-12"><span>{coursesViewed.sumOfViewed}</span>/{coursesViewed.sumOfItems}</div>
            </div>
            <ScrollingCarousel>
                {COURSES.map((i, idx) => <Item
                    handleRedirect={() => handleRedirect(CourseRoute)}
                    img={i.img}
                    id={i.id}
                    onSuccess={getFavorites}
                    route={i.url}
                    active={!!list[i.id]}
                    value={"course_info"}
                    index={idx}
                    key={'course' + idx}
                    viewed={calculateCourseProgress(allResults?.courses[idx])}
                    info={coursesInfo[idx]} />)}
            </ScrollingCarousel>
            <div className="main-content__header-wrapper row-container space-between mt-40">
                <div className="main-content__title typography-24">{gamesTitle}</div>
                <div className="main-content__title-count typography-12"><span>{gamesViewed.sumOfViewed}</span>/{gamesViewed.sumOfItems}</div>
            </div>
            <ScrollingCarousel>
                {GAMES.map((i, idx) => <Item
                    handleRedirect={() => handleRedirect(GAMES_ROUTE)}
                    viewed={calculateCourseProgress(allResults?.games[idx])}
                    id={i.id}
                    onSuccess={getFavorites}
                    route={i.url}
                    img={i.img}
                    active={!!list[i.id]}
                    key={'games' + idx}
                    value={"games_info"}
                    index={idx}
                    info={gamesInfo[idx]} />)}
            </ScrollingCarousel>
            <div className="main-content__header-wrapper row-container space-between mt-40">
                <div className="main-content__title typography-24">{scenariosTitle}</div>
                <div className="main-content__title-count typography-12"><span>{scenariosViewed.sumOfViewed}</span>/{scenariosViewed.sumOfItems}</div>
            </div>
            <ScrollingCarousel>
                {SCENARIOS.map((i, idx) => <Item
                    handleRedirect={() => handleRedirect(SCENARIOS_ROUTE)}
                    img={i.img}
                    key={'scenario' + idx}
                    id={i.id}
                    onSuccess={getFavorites}
                    route={i.url}
                    active={!!list[i.id]}
                    value={"scenarios_info"}
                    index={idx}
                    viewed={calculateCourseProgress(allResults?.scenarios[idx])}
                    info={scenariosInfo[idx]} />)}
            </ScrollingCarousel>
            <div className="main-content__header-wrapper row-container space-between mt-40">
                <div className="main-content__title typography-24">{premiumTitle}</div>
                <div className="main-content__title-count typography-12"><span>{premiumViewed.sumOfViewed}</span>/{premiumViewed.sumOfItems}</div>
            </div>
            <ScrollingCarousel>
                {PREMIUM.map((i, idx) => <Item
                    handleRedirect={() => handleRedirect(PREMIUM_ROUTE)}
                    img={i.img}
                    onSuccess={getFavorites}
                    id={i.id}
                    route={i.url}
                    key={'premium' + idx}
                    active={!!list[i.id]}
                    value={"premium_info"}
                    index={idx}
                    viewed={calculateCourseProgress(allResults?.premium[idx])}
                    info={premiumInfo[idx]} />)}
            </ScrollingCarousel>
        </div>
    )
}