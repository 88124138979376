import {ReactComponent as CheckIcon} from "assets/app/failed.svg";
import {ReactComponent as LogoIcon} from "assets/app/logo.svg";
import {useNavigate} from "react-router";
import '../../../components/TabsHeader/style.scss'
import {useTranslation} from "react-i18next";
import {Button} from "../../../components/lego/Button/Button";
import {ButtonThemes} from "../../../constants/button.constants";
import './styles.scss';
import cn from "classnames";
import {PAY_INFO_ROUTE} from "../../../constants/routes.constants";
import {PaymentModal} from "../../../components/onboarding/PayInfoContainer/PaymentModal";
import {useState} from "react";
import {priceValues} from "../../../constants/price";

const FailedSubscription = (props: {isHeader: boolean, nextRoute: string}) => {
    const navigate = useNavigate()
    const { t } = useTranslation('app');
    const localPrice = localStorage.getItem("price_plan")
    const isTimerOver = window.localStorage.getItem('isTimerOver') === 'true'
    const price = priceValues[Number(localPrice)]

    const [open, setIsOpen] = useState(false)
    const [wasOpen, setWasOpen] = useState(false)

    const onSend=()=>{
        if(!!price && !wasOpen){
            setIsOpen(true)
            setWasOpen(true)
        } else {
            navigate(props.nextRoute || '/')
        }
    }


    return (
        <div  className="change-password-wrapper success-page">
            {props.isHeader && <div className="success-container__header">
                <LogoIcon/>
            </div>}
            <div className="success-container">
                <div className={cn("success-container__icon", {'mt-40' : !props.isHeader})}>
                    <CheckIcon />
                </div>
                <div className="success-container__title typography-32 typography-500">
                    {t("failedTitle")}
                </div>
                <div className="success-container__subtitle align-center text-align-center typography-14">
                    {t("your_payment_has_been_failed")}
                </div>
            </div>
            <Button className="btn-change-password" onClick={onSend} text={t("try_again_btn")} buttonTheme={ButtonThemes.DARK} width={100}/>
            <PaymentModal
                setIsClosedFirsTime={() => {}}
                isClosedFirsTime={true}
                t={t}
                selected={1}
                open={open}
                setOpen={setIsOpen}
                price={price}
                isNewPrice={false}
                setIsNewPrice={()=>{}}
                isTimerOver={isTimerOver} />
        </div>
    )
}

export default FailedSubscription